import React, { useContext, useState } from 'react'
import Header from '../Coman/Header'
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { ProjectContext } from '../../ProjectContext';

export default function Otp() {
  const [otp, setotp] = useState("");
  const { toastSuccess } = useContext(ProjectContext);
  const { state } = useLocation();
  console.log("state", state.email);
  const navigate = useNavigate();

  const OtpVerify = async () => {
    await axios
      .post(process.env.REACT_APP_BACKEND_LINK, {
        method: "user",
        submethod: "verifyemail",
        otp: otp,
        eml: state.email,
        key: process.env.REACT_APP_KEY,
      })
      .then((res) => {
        if (res.data.error) {
          // seterror("Invalid credential");
          return "";
        }
        if (res.data.status === "1") {
          navigate("/login");
          toastSuccess("OTP Verified Successfully!")
        } else {
          // navigate("/registration");
          // seterror("Invalid credential");
        }
      });
  };
  const OtpResend = async () => {
    await axios
      .post(process.env.REACT_APP_BACKEND_LINK, {
        method: "user",
        submethod: "resendotp",
        eml: state.email,
        key: process.env.REACT_APP_KEY,
      })
      .then((res) => {
        if (res.data.error) {
          // seterror("Invalid credential");
          return "";
        }

        // if (res.data.status === "1") {
        //   navigate("/login");
        // } else {
        //   // navigate("/registration");
        //   // seterror("Invalid credential");
        // }
      });
  };
  return (
    <div>
      <Header />
      <section
        className="login-area pt-130 pb-90"
        data-background="assets/img/bg/sign-up-bg.jpg"
      >
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xxl-6 col-xl-7 col-lg-8">
              <div
                className="login-wrapper pos-rel mb-40 wow fadeInUp"
                style={{ visibility: "visible", animationName: "fadeInUp" }}
              >
                <div className=" login-inner">
                  <div className="login-content">
                    <h4>Verification</h4>
                    <div className="login-form">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="single-input-unit">
                            <label htmlFor="m-id">OTP</label>
                            <input
                              type="text"
                              className=''
                              placeholder="Enter Your Otp"
                              onChange={(e) => setotp(e.target.value)}
                            />
                            {/* <div className='d-flex justify-content-end'>
                              <span className='text-danger text-end mb-3' onClick={() => OtpResend()}>resend OTP</span>
                            </div> */}
                          </div>
                        </div>
                      </div>
                      <div className="login-btn">
                        <button className="fill-btn" onClick={() => OtpVerify()}>Verify </button>
                        {/* <div className="note">
                          Not yet registered?{" "}
                          <a href="rl" className="text-btn">
                            Sign up
                          </a>
                        </div> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}
