import React, { useContext, useEffect, useState } from "react";
import Header from "../Coman/Header";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { ProjectContext } from "../../ProjectContext";

export default function Registration() {
  const { getudata, toastSuccess, dbuser, account, } = useContext(ProjectContext);
  const [ref, setref] = useState("");
  const [email, setemail] = useState("");
  const [password, setpassword] = useState("");
  const [cpassword, setcpassword] = useState("");
  const [error, seterror] = useState("");
  const [referralUsername, setReferralUsername] = useState("");  // Store the input value
  const [referralMessage, setReferralMessage] = useState("");    // Store the validation message
  const [isReferralValid, setIsReferralValid] = useState(false); // Track whether referral is valid
  const navigate = useNavigate();
  const { link_ref_address } = useParams();
  const RegisterUser = async () => {
    await axios
      .post(process.env.REACT_APP_BACKEND_LINK, {
        method: "user",
        submethod: "insert",
        email: email,
        ref: referralUsername,
        password: password,
        c_password: cpassword,
        key: process.env.REACT_APP_KEY,
      })
      .then((res) => {
        if (res.data.error) {
          seterror(res.data.error);
          // seterror("Invalid credential");
          return "";
        }
        console.log("res.data.status", res.data.status);
        if (res.data.status === "1") {
          // localStorage.setItem("optkey", "sakjdbhaiusdhasndaskjdhdasdnasdnsa");
          navigate("/varification", {
            state: { email: res.data.email },
          });
          toastSuccess("OTP SEND")
        } else {
          seterror("Invalid credential");
        }
      });
  };


  // Handle the change in the referral username input box
  const handleReferralChange = async (e) => {
    const username = e.target.value;
    setReferralUsername(username);  // Update the referral username state

    if (username) {
      // Call the getudata function to check if the username exists
      const userData = await getudata(username);

      // If userData is empty or invalid, show an error message and disable inputs
      if (!userData) {
        setReferralMessage("Invalid or non-existent referral.");
        setIsReferralValid(false);  // Set referral as invalid
      } else {
        setReferralMessage("Referral is valid.");
        setIsReferralValid(true);   // Set referral as valid
      }
    } else {
      // Reset the message and disable inputs if the input is cleared
      setReferralMessage("");
      setIsReferralValid(false);
    }
  };

    useEffect(() => {
    if (dbuser !== null) {
      if (dbuser?.ref_address !== null) {
        setReferralUsername(dbuser.ref_address);
        // handleReferralChange();
      }
    } else {
      setReferralUsername(link_ref_address);
      const username = link_ref_address;
      setReferralUsername(username);  // Update the referral username state

      if (username) {
        // Call the getudata function to check if the username exists
        const userData = getudata(username);

        // If userData is empty or invalid, show an error message and disable inputs
        if (!userData) {
          setReferralMessage("Invalid or non-existent referral.");
          setIsReferralValid(false);  // Set referral as invalid
        } else {
          setReferralMessage("Referral is valid.");
          setIsReferralValid(true);   // Set referral as valid
        }
      } else {
        // Reset the message and disable inputs if the input is cleared
        setReferralMessage("");
        setIsReferralValid(false);
      }
      // handleReferralChange();
    }
  }, [dbuser, account, getudata]);
  useEffect(() => {
    // getudata();
    // console.log("getudata();", await getudata("bsoni3884"));

    localStorage.clear();
  });

  return (
    <div>
      <Header />
      <section
        className="login-area pt-130 pb-90"
        data-background="assets/img/bg/sign-up-bg.jpg"
      >
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xxl-6 col-xl-7 col-lg-8">
              <div
                className="login-wrapper pos-rel mb-40 wow fadeInUp"
                style={{ visibility: "visible", animationName: "fadeInUp" }}
              >
                <div className=" login-inner">
                  <div className="login-content">
                    <h4>Registration</h4>
                    <div className="login-form">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="single-input-unit">
                            <div className="d-flex justify-content-between">
                              <label htmlFor="m-id">Enter Ref Username</label>
                              <span className={isReferralValid ? "text-success" : "text-danger"}>{referralMessage}</span>
                            </div>
                            {dbuser !== null ? (
                              dbuser.ref_address !== null ? (
                                <input
                                  type="text"
                                  style={{ border: "1px solid black" }}
                                  value={referralUsername}
                                />
                              ) : (
                                <input
                                  type="text"
                                  id="referralUsername"
                                  value={referralUsername}
                                  onChange={handleReferralChange}  // On change event triggers the function
                                  autocomplete="off"
                                />
                              )
                            ) : (
                              <input
                                type="text"
                                id="referralUsername"
                                value={referralUsername}
                                onChange={handleReferralChange}  // On change event triggers the function
                                autocomplete="off"
                              />
                            )}
                          </div>
                          {/* Display the validation message */}
                          {/* <div className="single-input-unit">
                              <label htmlFor="m-id">Enter Ref Username</label>
                              <input
                                type="text"
                                placeholder="Your Ref Username"
                                onChange={(e) => setref(e.target.value)}
                              />
                            </div> */}
                        </div>
                        <div className="col-md-12">
                          <div className="single-input-unit">
                            <div className="d-flex justify-content-between">
                              <label htmlFor="u-name">Email </label>
                              <span className="text-warnig">Please enter valid email!</span>
                            </div>
                            <input
                              type="email"
                              placeholder="Username"
                              onChange={(e) => setemail(e.target.value)}
                              disabled={!isReferralValid}
                              required
                            />
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="single-input-unit">
                            <label htmlFor="password">Password</label>
                            <input
                              type="password"
                              placeholder="********"
                              onChange={(e) => setpassword(e.target.value)}
                              disabled={!isReferralValid}
                            />
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="single-input-unit">
                            <label htmlFor="password">Confirm Password</label>
                            <input
                              type="password"
                              placeholder="********"
                              onChange={(e) => setcpassword(e.target.value)}
                              disabled={!isReferralValid}
                            />
                          </div>
                          <div className="d-flex justify-content-center">
                            <span className="text-danger">{error}</span>
                          </div>
                        </div>
                      </div>
                      <div className="login-btn">
                        <button className="fill-btn" onClick={() => RegisterUser()} disabled={!isReferralValid}>Register</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}