import React, { useEffect, useState, useContext } from "react";
import Header from "../Coman/Header";
import axios from "axios";
import { useNavigate } from "react-router-dom";
 import { ProjectContext } from "../../ProjectContext";

export default function Login() {
    const { setaddress } = useContext(ProjectContext);
  const [email, setemail] = useState("");
  const [password, setpassword] = useState("");
  const [error, seterror] = useState("");
  const navigate = useNavigate();

  const getCheck = async () => {
    await axios
      .post(process.env.REACT_APP_BACKEND_LINK, {
        method: "userlogin",
        submethod: "check",
        address: email,
        password: password,
        key: process.env.REACT_APP_KEY,
      })
      .then((res) => {
        if (res.data.error) {
          return "";
        }
        if (res.data.status === "1") {
          localStorage.setItem("odkpfub", "5Dg4ZCDAxWoxjgGHRj6BAMQ4dUDC");
            //   localStorage.setItem("address", res.data.address);
          localStorage.setItem("q4iDssdDn6x8f4zg3BZr", res.data.address);
          setaddress(res?.data?.address);
          navigate("/dashboard", {
            state: { email: res.data.email },
          });
        } else {
          seterror("Invalid credential");
        }
      });
  };

  useEffect(() => {
    localStorage.clear();
  });

  return (
    <>
      {" "}
      <Header />
      <section
        className="login-area pt-130 pb-90"
        data-background="assets/img/bg/sign-up-bg.jpg"
      >
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xxl-6 col-xl-7 col-lg-8">
              <div
                className="login-wrapper pos-rel mb-40 wow fadeInUp"
                style={{ visibility: "visible", animationName: "fadeInUp" }}
              >
                <div className=" login-inner">
                  <div className="login-content">
                    <h4>Login</h4>
                    <div className="login-form" action="#">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="single-input-unit">
                            <label htmlFor="m-id">Username</label>
                            <input
                              type="text"
                              placeholder="Your Username"
                              onChange={(e) => setemail(e.target.value)}
                            />
                          </div>
                        </div>

                        <div className="col-md-12">
                          <div className="single-input-unit">
                            <label htmlFor="password">Password</label>
                            <input
                              type="password"
                              name="password"
                              id="password"
                              placeholder="********"
                              onChange={(e) => setpassword(e.target.value)}
                              onKeyPress={(e) =>
                                e.charCode === 13 ? getCheck() : ""
                              }
                            />
                          </div>
                        </div>
                      </div>
                      {error ? (
                        <div class="alert alert-danger solid alert-square ">
                          {error}
                        </div>
                      ) : (
                        ""
                      )}
                      <button className="fill-btn" onClick={() => getCheck()}>
                        Login
                      </button>
                      {/* <div className="note">
                        Not yet registered?{" "}
                        <a href="rl" className="text-btn">
                          Sign up
                        </a>
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section >
    </ >
  );
}