import React, { useContext, useEffect, useState } from "react";
import Sidebar from "../Coman/Sidebar";
import { ProjectContext } from "../../ProjectContext";
import { useParams } from "react-router-dom";
import axios from 'axios';

export default function Stake() {
  const {
    account,
    balance,
    toastSuccess,
    library,
    dbuser,
    tokenrate, formatAddress,
    stakcontract,
    toastError,
    getudata,
    settings,
    esigner,
    ethers,
    getuserDB,
    connectMetamask,
  } = useContext(ProjectContext)
  const [amount, setamount] = useState(0);
  const [total_tokens, settotal_tokens] = useState(0);
  const [trdone, settrdone] = useState(false);
  const [hash, sethash] = useState("");
  const [referral, setreferral] = useState("");
  const { link_ref_address } = useParams();

  const onDeposit = async () => {
    settrdone(true);
    sethash("");
    if (!account) {
      connectMetamask();
      toastError("Connect first");
      settrdone(false);
      return;
    }
    console.log("dassd", amount);
    if (!amount) {
      toastError("Enter Amount");
      settrdone(false);
      return;
    }
    // if (Number(balance) < Number(total_tokens)) {
    //   toastError("Insufficient balance");
    //   return;
    // }
    if (50 > amount) {
      toastError("Minimum $50");
      settrdone(false);
      return;
    }
    // if (!referral) {
    //   toastError("Enter Referral Address");
    //   settrdone(false);
    //   return;
    // }
    // var data = await getudata(referral);
    // if (data.data.error) {
    //   toastError("Invalid Referral Address");
    //   settrdone(false);
    //   return "";
    // }
    // if (dbuser !== null && dbuser.last_staking > amount) {
    //     toastError(`Stake ${dbuser.last_staking} or more`);
    //     settrdone(false);
    //     return;
    // }
    let contract = new library.Contract(
      settings.contract,
      JSON.parse(settings.contract_abi),
      esigner
    );
    let token_contract = new library.Contract(
      settings.token,
      JSON.parse(settings.token_abi),
      esigner
    );
    const allowance = await token_contract.allowance(
      account,
      settings.contract
    );

    async function main() {
      // var gsl = await stakcontract//   .stake(ethers.utils.parseEther(`${amount}`), incappass, referral)
      //   .estimateGas( { from: account, value: `${Number(calcstakingfee) + 1000000000}` });
      //   console.log("gasghd ",gsl,await library.eth.getGasPrice()
      //   );
      try {
        var stk = null
        stk = await contract.stake(ethers.utils.parseEther(`${amount}`), referral);
        // const randomHash = Array.from(crypto.getRandomValues(new Uint8Array(16))).map(byte => byte.toString(16).padStart(2, '0')).join('');

        const receipt = await stk.wait();
        sethash(receipt.transactionHash);
        // sethash(randomHash);
        toastSuccess("Transaction done");
        await axios
          .post(process.env.REACT_APP_BACKEND_LINK, {
            method: "transaction",
            submethod: "insert",
            key: process.env.REACT_APP_KEY,
            address: account,
            ref_address: referral,
            hash: receipt.transactionHash,
            // hash: randomHash,
            amount: amount,
            token_rate: tokenrate,
            token: total_tokens
          })
          .then((res) => {
            console.log("res", res.data);
            // var dedata = decryptData(res.data);
            if (res.data.error) {
              return "";
            }
          });
        getuserDB();
        settrdone(false);
        // navigate("/dashboard");

      } catch (error) {
        console.log("Error", error);
        toastError("Transaction declined");
        settrdone(false);
        return;
      }

    }
    // if (Number(library.utils.fromWei(allowance, "ether")) < total_tokens) {
    if (Number(ethers.utils.formatEther(allowance)) < amount) {
      // if (false) {
      try {
        var approv = await token_contract.approve(
          settings.contract,
          ethers.utils.parseEther("100000000")
        );
        const receipt = await approv.wait();
        console.log(receipt);
        toastSuccess("Token Approved");
        main();
      } catch (error) {
        console.log("error", error);
        toastError("Token not approved");
        settrdone(false);
        return;
      }
    } else {
      main();
    }
  };
  useEffect(() => {
    if (dbuser !== null) {
      if (dbuser.ref_address !== null) {
        setreferral(dbuser.ref_address);
      }
    } else {
      setreferral(link_ref_address);
    }
  }, [dbuser, account]);
  useEffect(() => {
    settotal_tokens(amount / tokenrate);
  }, [amount, tokenrate]);

  return (
    <div>
      <Sidebar />
      <div style={{ backgroundColor: "#eff1f5" }}>
        <div className="banner-area banner-area3 pos-rel pt-130">
          <div className="container c-container-1">
            {/* <h2>Stake</h2> */}
            <div className="row justify-content-center mt-4">
              <div className="col-xxl-6 col-xl-7 col-lg-8">
                <div
                  className="login-wrapper pos-rel mb-40 wow fadeInUp bg-white"
                  style={{ visibility: "visible", animationName: "fadeInUp" }}
                >
                  <div className=" login-inner">
                    <div className="login-content">
                      <h4>Stake</h4>
                      <div className="row">
                        <div className="col-md-12">
                          {/* <div className="single-input-unit">
                            <label htmlFor="ref">Referral Address</label>
                            {dbuser !== null ? (
                              dbuser.ref_address !== null ? (
                                <input
                                  type="text"
                                  style={{ border: "1px solid black" }}
                                  value={referral}
                                />
                              ) : (
                                <input
                                  type="text"
                                  placeholder="Enter Referral Address"
                                  value={referral}
                                  style={{ border: "1px solid black" }}
                                  onChange={(e) => { setreferral(e.target.value); }
                                  }
                                />
                              )
                            ) : (
                              <input
                                type="text"
                                placeholder="Enter Referral Address"
                                value={referral}
                                style={{ border: "1px solid black" }}
                                onChange={(e) => { setreferral(e.target.value); }

                                }
                              />
                            )}

                          </div> */}
                          <h4 className="text-center mb-4">Min: $50</h4>
                          <div className="single-input-unit">
                            <label htmlFor="amt">Amount</label>
                            <input
                              type="text"
                              name="amt"
                              id="amt"
                              placeholder="Enter Amount"
                              onChange={(e) =>
                                setamount(e.target.value)
                              }
                              style={{ border: "1px solid black" }}
                            />
                          </div>
                          <div className="art-info-wrapper">
                            <ul>
                              <li>
                                <span className="art-info-title">Token Rate</span>
                                ${tokenrate}
                              </li>
                              <li>
                                <span className="art-info-title">
                                  Tokens
                                </span>
                                {total_tokens} {process.env.REACT_APP_TICKER}
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div className="login-btn">
                        {hash ? (
                          <div className="p-3 text-center">
                            Hash :{" "}
                            <a
                              href={`${process.env.REACT_APP_EXPLORER}tx/${hash}`}
                              target="_blank"
                              rel="noreferrer"
                            >
                              #: {formatAddress(hash)}
                            </a>
                          </div>
                        ) : (
                          ""
                        )}
                        {trdone ? (
                          <div className="text-center">
                            <img
                              src="/assets/loader.gif"
                              alt="loading"
                              style={{ width: '100px' }}
                            />
                          </div>
                        ) : (
                          <button className="fill-btn" onClick={() => onDeposit()}>Stake</button>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
