import React, { useContext, useEffect, useState } from "react";
import { ProjectContext } from "../../../ProjectContext";
import axios from "axios";

export default function WithdrawalBox() {
  const {
    address,
    toastSuccess, dbbalance,
    tokenrate,
    toastError, dbuser,
    getuserDB,
    connectMetamask,
  } = useContext(ProjectContext)
  const [amount, setamount] = useState(0);
  const [waddress, setWAddress] = useState("");
  const [rcv_amount, setrcv_amount] = useState(0);
  const [fees, setfees] = useState(0);
  const [total_tokens, settotal_tokens] = useState(0);
  const [trdone, settrdone] = useState(false);

  console.log("address",dbuser.address);
  
  const withdraw = async () => {
    if (!waddress) {
      // connectMetamask();
      toastError("Add Address first");
      settrdone(false);
      return;
    }
    if (!amount || amount < 0) {
      toastError("Enter amount");
      settrdone(false);
      return;
    }
    if (dbuser.restake) {
      toastError("Restake account");
      settrdone(false);
      return;
    }
    if (amount < 10) {
      toastError("Min $10 required");
      settrdone(false);
      return;
    }
    if (Number(amount) > dbbalance) {
      toastError("Insufficient Balance");
      settrdone(false);
      return;
    }
    await axios
      .post(process.env.REACT_APP_BACKEND_LINK, {
        method: "withdrawal",
        submethod: "insert",
        key: process.env.REACT_APP_KEY,
        address: waddress,
        username: dbuser.address,
        amount: amount,
        token: total_tokens,
        fee: fees,//admin
        t_rcv: rcv_amount,
        rate: tokenrate,
      })
      .then((res) => {
        // var dedata = decryptData(res.data);
        if (res.data.error) {
          toastError(res.data.error);
          return "";
        }
        toastSuccess(res.data.data);
      });
    getuserDB();
    // getpData()
    // getData()
    settrdone(false);
  }
  useEffect(() => {
    var tmt = amount / tokenrate;
    var tmtfee = (tmt * 10) / 100;
    settotal_tokens(tmt);
    setfees(tmtfee);
    setrcv_amount(tmt - tmtfee);
  }, [amount, tokenrate]);


  return (
    <div>
      <div style={{ backgroundColor: "#eff1f5" }}>
        <div className="banner-area banner-area3 pos-rel pt-130 vh-100">
          <div className="container c-container-1">
            <h2 className="text-center">Withdrawal</h2>
            <div className="row justify-content-center mt-4">
              <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-12">
                <div
                  className="login-wrapper pos-rel mb-40 wow fadeInUp bg-white"
                  style={{ visibility: "visible", animationName: "fadeInUp" }}
                >
                  <div className=" login-inner">
                    <div className="login-content">
                      <h4>Sign in Account</h4>
                      <div className="row">
                        <div className="col-md-12">
                          <div className="single-input-unit">
                            <div className="d-flex justify-content-between">
                              <label htmlFor="m-id">Address</label>
                            </div>
                            <input
                              type="text"
                              value={waddress}
                              onChange={(e) =>
                                setWAddress(e.target.value)
                              }
                              placeholder="Enter Amount"
                              style={{ border: "1px solid black" }}
                            />
                          </div>
                          <div className="single-input-unit">
                            <div className="d-flex justify-content-between">
                              <label htmlFor="m-id">Withdraw</label>
                              <h4 className="m-0">${dbbalance}</h4>
                            </div>
                            <input
                              type="text"
                              value={amount}
                              onChange={(e) =>
                                setamount(e.target.value)
                              }
                              placeholder="Enter Amount"
                              style={{ border: "1px solid black" }}
                            />
                          </div>
                        </div>
                        <div className="art-info-wrapper">
                          <ul>
                            <li>
                              <span className="art-info-title">Fee</span> ${fees} (10%)
                            </li>
                            <li>
                              <span className="art-info-title">Received Amount</span>
                              $ {rcv_amount}
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="login-btn">
                        <button className="fill-btn" onClick={() => withdraw()}>Withdraw now</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
