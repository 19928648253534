import React, { useContext, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { ProjectContext } from '../../ProjectContext';

export default function OverView() {
  const {
    // toastError,
    setaddress,
  } = useContext(ProjectContext)
  const [oAddr, setoAddr] = useState(null);
  var navigate = useNavigate();

  const getDta = async () => {
    if (!oAddr) {
      console.log("Enter address");
      return
    }
    localStorage.setItem("odkpfub", "5Dg4ZCDAxWoxjgGHRj6BAMQ4dUDC");
    localStorage.setItem("q4iDssdDn6x8f4zg3BZr", oAddr);
    setaddress(oAddr)
    navigate('/dashboard')
  };
  return (

    <div>
      <div style={{ backgroundColor: "#eff1f5" }}>
        <div className="banner-area banner-area3 pos-rel pt-130 vh-100">
          <div className="container">
            <h2 className="text-center">Overview</h2>
            <div className="row justify-content-center mt-4">
              <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-12">
                <div
                  className="login-wrapper pos-rel mb-40 wow fadeInUp bg-white"
                  style={{ visibility: "visible", animationName: "fadeInUp" }}
                >
                  <div className=" login-inner">
                    <div className="login-content">
                      <h4>Overview</h4>
                      <div className="row">
                        <div className="col-md-12">
                          <div className="single-input-unit">
                            <div className="d-flex justify-content-between">
                              <label htmlFor="m-id">Address</label>
                            </div>
                            <input
                              placeholder={"Enter address"}
                              type="text"
                              onChange={(e) => setoAddr(e.target.value)}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="login-btn">
                        <button className="fill-btn" onClick={() => getDta()}>Go🚀</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <section className="dashboard-section body-collapse pay step deposit-money withdraw-money" style={{ background: "#F7F9FD" }}>
        <div className="overlay pt-120">
          <div className="container-fruid">
            <div className="main-content" style={{ background: "#fff" }}>
              <div className="row pb-120  justify-content-center">
                <div className="col-12">
                  <div className="table-area">
                    <div>
                      <form action="#" >
                        <div className="row justify-content-center mt-5">
                          <div className="col-xl-7 p-4">
                            <div className="exchange-content">
                              <div className="send-banance">
                                <span className="mdr">Address</span>
                                <div className="input-area">
                                  <input
                                    className="xxlr"
                                    placeholder={"Enter address"}
                                    type="text"
                                    onChange={(e) => setoAddr(e.target.value)}
                                  />

                                </div>
                              </div>

                            </div>
                          </div>
                        </div>
                        <div className="footer-area mt-40">
                          <a type='button' className="active" onClick={() => getDta()}>
                            
                          </a>
                        </div>
                      </form>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}
    </div>
  )
}
