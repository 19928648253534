import React, { useContext, useEffect, useState } from "react";
import Header2 from "../Coman/Header2";
import Sidebar from "../Coman/Sidebar";
import { ProjectContext } from "../../ProjectContext";
import axios from "axios";
import Pagination from "../Coman/Pagination";
export default function Dividend() {
  const { account, copyaddress, formatAddress } = useContext(ProjectContext)
  const [data, setdata] = useState([]);
  const [isLoading, setisLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [pages, setPages] = useState(1);

  const getData = async () => {
    if (!account) {
      return;
    }
    setisLoading(true);

    await axios
      .post(process.env.REACT_APP_BACKEND_LINK, {
        method: "sincome",
        submethod: "getbyid",
        // address: "0x6652e765ccd969816503c40da6e91816517b8691",
        address: account,
        key: process.env.REACT_APP_KEY,
        page: currentPage,
        pageSize: pageSize
      })
      .then((res) => {
        setisLoading(false);
        // var dedata = decryptData(res.data);
        if (res.data.error) {
          return "";
        }
        setdata(res.data.data);
        setPages(Math.ceil(res.data.dataLength / pageSize));
      });
  };
  useEffect(() => {
    getData();
  }, [account, pages, pageSize, currentPage]);

  return (
    <div>
      <Sidebar />
      <div style={{ backgroundColor: "#eff1f5" }}>
        <div className="banner-area banner-area3 pos-rel pt-130 vh-100">
          <div className="container c-container-1">
            <h2>Dividend</h2>
            <div
              className="rank-list-container wow fadeInUp mt-4"
              style={{ visibility: "visible", animationName: "fadeInUp" }}
            >
              <div className="rank-list-wrapper mb-30">
                <div className="table-responsive">
                  <table className="table">
                    <thead>
                      <tr>
                        <th scope="col"> Sr</th>
                        <th scope="col"> Date</th>
                        <th scope="col"> Stake Amount</th>
                        <th scope="col"> Reward(%)</th>
                        <th scope="col"> Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr className="text-center">
                        <td className="text-center" colSpan={10}>
                          {isLoading ? "Data is loading" : ""}
                        </td>
                      </tr>
                      {!isLoading ? (
                        !data.length ? (
                          <tr className="text-center">
                            <td className="text-center" colSpan={10}>
                              <span className="w-100">No data found</span>
                            </td>
                          </tr>
                        ) : (
                          ""
                        )
                      ) : (
                        ""
                      )}
                      {data.map((ele, i) => {
                        return (
                          <tr>
                            <td >
                              {ele.id}
                            </td>
                            <td >
                              {ele.createdAt}
                            </td>
                            <td >
                              $ {ele.staking}
                            </td>
                            <td >
                              $ {ele.income} ({ele.income_per === 0.6 ? "🚀" : ""}{ele.income_per} %)
                            </td>
                            <td>
                              {ele.flushed ? "Flushed" : ""}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                  <Pagination
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    pageSize={pageSize}
                    setPageSize={setPageSize}
                    pages={pages}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
