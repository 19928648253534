import React from 'react';
import './App.css';
import { BrowserRouter, Outlet, Route, Routes } from 'react-router-dom';
import Index from './Componant/Home/Index';
import Dashboard from './Componant/Dashboard/Dashboard';
import Staking from './Componant/Dashboard/Staking';
import Withdrawal from './Componant/Dashboard/Withdrawal/Withdrawal';
import Team from './Componant/Dashboard/Team';
import Dividend from './Componant/Dashboard/Dividend';
import DirectReward from './Componant/Dashboard/DirectReward';
import LevelReward from './Componant/Dashboard/LevelReward';
import MatchingReward from './Componant/Dashboard/MatchingReward';
import RoyalRank from './Componant/Dashboard/RoyalRank';
import Stake from './Componant/Dashboard/Stake';
import WithdrawHistory from './Componant/Dashboard/WithdrawHistory';
import QrCodeAuth from './Componant/Login/QrCodeAuth';
import Login from './Componant/Login/Login';
import ProtecteRoutes from './ProtecteRoutes';
import Registration from './Componant/Login/Registration';
import Otp from './Componant/Login/Otp';
import OverView from './Componant/Dashboard/OverView';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        {/* <Route path="/" element={<Outlet />}> */}
        <Route path="/" element={<Index />} />
        <Route path="/registration" element={<Registration />} />
        <Route path="/registration/:link_ref_address" element={<Registration />} />
        <Route path="/varification" element={<Otp />} />
        <Route path="/login" element={<Login />} />
        {/* <Route path="/authentication" element={<QrCodeAuth />} />

          <Route element={<ProtecteRoutes />}> */}
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/staking" element={<Staking />} />
        <Route path="/team" element={<Team />} />
        <Route path="/dividend" element={<Dividend />} />
        <Route path="/direct-reward" element={<DirectReward />} />
        <Route path="/level-reward" element={<LevelReward />} />
        <Route path="/matching-reward" element={<MatchingReward />} />
        <Route path="/royal-rank" element={<RoyalRank />} />
        <Route path="/stake" element={<Stake />} />
        <Route path="/stake/:link_ref_address" element={<Stake />} />

        <Route path="/withdrawal" element={< Withdrawal />} />
        <Route path="/withdrawal-history" element={< WithdrawHistory />} />
        <Route path="/overview" element={<OverView />} />
        {/* </Route> */}
        {/* </Route> */}
      </Routes>
    </BrowserRouter>
  );
}

export default App;