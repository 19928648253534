/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";

const Pagination = ({
  currentPage,
  setCurrentPage,
  pageSize,
  setPageSize,
  pages,
}) => {
  return (
    <>
      <div className="wow fadeInUp" style={{ visibility: 'visible', animationName: 'fadeInUp' }}>
        <div className="col-12">
          <div className="basic-pagination mt-20 mb-30">
            <ul>
              <li >
                <a
                  type="button"
                  className="prev page-numbers"
                  onClick={() => setCurrentPage(1)}
                  disabled={currentPage === 1 ? true : false}
                >
                  <i className="fal fa-angle-left" />
                </a>
              </li>
              {currentPage - 2 > 0 ? (
                <li >
                  <a
                    type="button"

                    onClick={() => setCurrentPage((prev) => prev - 2)}
                  ><span className="page-numbers" >
                      {currentPage - 2}
                    </span>
                  </a>
                </li>
              ) : (
                ""
              )}
              {currentPage - 1 > 0 ? (
                <li >
                  <a
                    type="button"

                    onClick={() => setCurrentPage((prev) => prev - 1)}
                  ><span className="page-numbers" >
                      {currentPage - 1}
                    </span>
                  </a>
                </li>
              ) : (
                ""
              )}
              <li >
                <a type="button" disabled={true}>
                  <span className="page-numbers current" >
                    {currentPage}
                  </span>
                </a>
              </li>
              {currentPage + 1 <= pages ? (
                <li>
                  <a
                    type="button"

                    onClick={() => setCurrentPage((prev) => prev + 1)}
                  >
                    <span className="page-numbers" >
                      {currentPage + 1}
                    </span>
                  </a>
                </li>
              ) : (
                ""
              )}
              {currentPage + 2 <= pages ? (
                <li>
                  <a
                    type="button"
                    className="next page-numbers"
                    onClick={() => setCurrentPage((prev) => prev + 2)}
                  >
                    <span className="page-numbers" >
                      {currentPage + 2}
                    </span>
                  </a>
                </li>
              ) : (
                ""
              )}
              <li>
                <a
                  type="button"
                  className="next page-numbers"
                  onClick={() => setCurrentPage(pages)}
                  disabled={currentPage === pages ? true : false}
                >
                  <i className="fal fa-angle-right" />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default Pagination;
