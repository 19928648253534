import React from 'react'
import Header2 from '../../Coman/Header2'
import Sidebar from '../../Coman/Sidebar'
import WithdrawalBox from './WithdrawalBox'

export default function Withdrawal() {
  return (
    <div>
          <Header2 />
          <Sidebar />
          <WithdrawalBox/>
     
    </div>
  )
}
