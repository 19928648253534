import React, { useContext, useEffect, useState } from "react";
import Header2 from "../Coman/Header2";
import Sidebar from "../Coman/Sidebar";
import { ProjectContext } from "../../ProjectContext";
import axios from "axios";
import Pagination from "../Coman/Pagination";

export default function WithdrawHistory() {
  const { account, formatAddress, copyaddress } = useContext(ProjectContext)
  const [data, setdata] = useState([]);
  const [datap, setdatap] = useState(null);
  const [isLoading, setisLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(25);
  const [pages, setPages] = useState(1);
  const [sumIncome, setsumIncome] = useState(0);

  const getData = async () => {
    if (!account) {
      return;
    }
    setisLoading(true);

    await axios
      .post(process.env.REACT_APP_BACKEND_LINK, {
        method: "withdrawal",
        submethod: "getbyid",
        address: account,
        key: process.env.REACT_APP_KEY,
        page: currentPage,
        pageSize: pageSize
      })
      .then((res) => {
        console.log("vvsdf", res.data);
        setisLoading(false);
        // var dedata = decryptData(res.data);
        if (res.data.error) {
          return "";
        }
        setdata(res.data.data);
        setPages(Math.ceil(res.data.dataLength / pageSize));
      });
  };
  const getpData = async () => {
    if (!account) {
      return;
    }
    setisLoading(true);

    await axios
      .post(process.env.REACT_APP_BACKEND_LINK, {
        method: "withdrawal",
        submethod: "getpbyid",
        address: account,
        key: process.env.REACT_APP_KEY,
        page: currentPage,
        pageSize: pageSize
      })
      .then((res) => {
        // var dedata = decryptData(res.data);
        console.log("dasbdjahsjdhajsd", res.data);
        if (res.data.error) {
          return "";
        }
        setdatap(res.data.data);
      });
  };

  useEffect(() => {
    getData();
  }, [account, pages, pageSize, currentPage]);
  useEffect(() => {
    getData();
    getpData()
  }, [account]);
  return (
    <div>
      <Sidebar />
      <div style={{ backgroundColor: "#eff1f5" }}>
        <div className="banner-area banner-area3 pos-rel pt-130 vh-100">
          {datap !== null ?
            <div className="container c-container-1">
              <h2>Pending Withdrawal</h2>
              <div
                className="rank-list-container wow fadeInUp mt-4"
                style={{ visibility: "visible", animationName: "fadeInUp" }}
              >

                <div className="table-responsive">
                  <table className="table tournament-table">
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Date</th>
                        <th>Amount</th>
                        <th>Admin Fee</th>
                        <th>Received Amount</th>
                        <th>Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {datap.map((ele, i) => {
                        return (
                          <tr>
                            <td>
                              {i + 1}
                            </td>
                            <td > {ele.datetime}
                            </td>
                            <td >
                              $ {ele.amount}
                            </td>

                            <td >
                              {ele.fee} USDT (5%)
                            </td>

                            <td >
                              {ele.t_rcv} USDT
                            </td>

                            <td className='text-warning'> Pending
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>

              </div>
            </div> : ''}
          <div className="container c-container-1 pt-5">
            <h2>Withdrawals</h2>
            <div
              className="rank-list-container wow fadeInUp mt-4"
              style={{ visibility: "visible", animationName: "fadeInUp" }}
            >

              <div className="table-responsive">
                <table className="table tournament-table">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Date</th>
                      <th>Hash</th>
                      <th>Amount</th>
                      <th>Admin Fee</th>
                      <th>Received Amount</th>
                      <th>Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr className="text-center">
                      {isLoading ? "Data is loading" : ""}
                    </tr>
                    {!isLoading ? (
                      !data.length ? (
                        <tr className="text-center">
                          <td className="text-center" colSpan={8}>
                            <span className="w-100">No data found</span>
                          </td>
                        </tr>
                      ) : (
                        ""
                      )
                    ) : (
                      ""
                    )}

                    {data.map((ele, i) => {
                      return (
                        <tr>
                          <td>
                            {i + 1}
                          </td>
                          <td > {ele.datetime}
                          </td>
                          <td >
                            <span
                              onClick={() => copyaddress(ele.hash)}
                            >
                              <a href={`${process.env.REACT_APP_EXPLORER}tx/${ele.hash}`} target='_blank'>
                                {formatAddress(ele.hash)}{" "}
                              </a>
                              <i className="fa fa-copy"></i>
                            </span>
                          </td>
                          <td >
                            $ {ele.amount}
                          </td>
                          <td >
                            {ele.fee} USDT (5%)
                          </td>
                          <td >
                            {ele.t_rcv} USDT
                          </td>
                          <td className='text-success'> Success
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
                <Pagination
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                  pageSize={pageSize}
                  setPageSize={setPageSize}
                  pages={pages}
                />
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
