import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import { Line, Circle } from 'rc-progress';
import { ProjectContext } from "../../ProjectContext";
import axios from "axios";

export default function DashboardMain() {
  const { copyaddress, account, dbbalance, dbuser, c_pool_data, formatAddress } = useContext(ProjectContext);
  // console.log("db address", dbuser.address);

  const [qrCode, setQrCode] = useState("");
  const getQRCode = async () => {
    await axios
      .post(process.env.REACT_APP_BACKEND_LINK, {
        method: "getqrcode",
        submethod: "get",
        user_name: "ajay4576",
        key: process.env.REACT_APP_KEY,
      })
      .then((res) => {
        setQrCode(res.data.qr_code);
        console.log(res.data);
        if (res.data.error) {
          return "";
        }
      });
  };
  return (
    <div style={{ backgroundColor: "#eff1f5" }}>
      <div className="banner-area banner-area3 pos-rel pt-130">
        <div className="container c-container-1">
          <div className="col-lg-12 col-md-12 col-sm-12">
            <div className="section-title1">
              <h2 className="section-main-title1 ">My Account </h2>
              {/* <button className="btn btn-primary" onClick={() => getQRCode()}> Get QR</button>
              {qrCode && <img src={qrCode} alt="QR Code" />} */}
            </div>
          </div>
          <div className="row">
            <div className="col-xl-6">
              <div className="work-process-single mb-30 pos-rel text-start mt--6 ">
                <div className="work-process-content">
                  <div className="d-flex justify-content-between">
                    <div>
                      <h5 className=" text-start">
                        <a>Balance</a>
                      </h5>
                      <h1 className="text-start ">${dbbalance}</h1>
                    </div>
                    <div>
                      <h5 className=" text-start">
                        <a>Rank</a>
                      </h5>
                      <h1 className="text-start ">
                        {dbuser !== null
                          ? dbuser.rank_title === null
                            ? "No Rank"
                            : dbuser.rank_title
                          : "No Rank"}</h1>
                    </div>
                  </div>
                  <div className="d-flex justify-content-between mt-4">
                    <div>
                      <h4 className="process-title">$ {dbuser ? dbuser.r_aleg : 0}</h4>
                      <h4>R. A Business</h4>
                    </div>
                    <div>
                      <h4 className="process-title">$ {dbuser ? dbuser.r_bleg : 0}</h4>
                      <h4>R. B Business</h4>
                    </div>
                    <div>
                      <h4 className="process-title">$ {dbuser ? dbuser.r_cleg : 0}</h4>
                      <h4>R. O Business</h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-6">
              <div className="work-process-single mb-30 pos-rel text-start">
                <div className="work-process-content">
                  <h5 className=" text-start">
                    <a onClick={() => copyaddress(dbuser !== null
                      ? dbuser.address
                      : "no address")}>
                      My Account -{dbuser !== null
                        ? dbuser.address
                        : "no referral"}<i className="fa fa-copy ms-3"></i>
                    </a>
                  </h5>
                  <h5 className=" text-start" style={{ overflow: " auto =" }}>
                    <a onClick={() =>
                      copyaddress(
                        dbuser !== null
                          ? dbuser.ref_address
                          : "no referral"
                      )
                    }>
                      Referral - {dbuser !== null
                        ? dbuser.ref_address
                        : "no referral"}<i className="fa fa-copy ms-3"></i>
                    </a>
                  </h5>
                  <div className="mt-4 d-flex justify-content-center">
                    <Link to="/stake" className="fill-btn me-3">
                      Stake Now
                    </Link>
                    <Link to={'/withdrawal'} className="fill-btn ms-3">
                      Withdrawal
                    </Link>
                  </div>
                  <div className="mt-3 d-flex justify-content-center">
                    <button type="button" className="fill-btn me-3" onClick={() => copyaddress(`${process.env.REACT_APP_LINK}registration/${dbuser !== null
                          ? dbuser.address
                          : "no referral"}`)}>
                      Sponsor Link
                    </button>
                    <a href="#" className="fill-btn ms-3">
                      Buy BBT
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-12 col-lg-12">
            <div
              className="row wow fadeInUp"
              style={{ visibility: "visible", animationName: "fadeInUp" }}
            >
              <div className="col-lg-8 col-md-8 col-sm-8">
                <div className="section-title1">
                  <h2 className="section-main-title1 ">Capability </h2>
                </div>
              </div>
              <div className="col-lg-4 col-md-4 col-sm-4">
              </div>
              <div className=" col-12">
                <div className="creator-single creator-single-short creator-single-filled mb-30">
                  <div className="creator-wraper">
                    <Line percent={10} strokeWidth={1} strokeColor="#eb6502"
                      trailColor="#005c9f" />
                    <div className="d-flex justify-content-between">
                      <h4>
                        0
                      </h4>
                      <h4>
                        0
                      </h4>
                      <h4>
                        {dbuser ? dbuser.direct_team > 0 ? "3x" : "2x" : '0X'}
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className>
              <div
                className="row wow fadeInUp"
                style={{ visibility: "visible", animationName: "fadeInUp" }}
              >
                <div className="col-lg-12 col-md-12 col-sm-12">
                  <div className="section-title1">
                    <h2 className="section-main-title1 ">Statistics </h2>
                  </div>
                </div>
                <div className="col-xl-3 col-lg-3 col-md-4 col-12">
                  <div className="creator-single creator-single-short creator-single-filled mb-30">
                    <div className="creator-wraper">
                      <div className="creator-inner">
                        <div className="creator-content pos-rel creator-short-content">
                          <div className="profile-img pos-rel">
                            <a>
                              <img
                                src="assets/img/globl/dashboard/stake.svg"
                                alt="profile-img"
                              />
                            </a>
                          </div>
                          <div className="creator-info">
                            <div className>
                              <h4 className="artist-name pos-rel">
                                <a>${dbuser ? dbuser.t_staking : 0}</a>
                              </h4>

                              <div className="artist-meta-item">
                                <div className="artist-created">Stake</div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-3 col-lg-3 col-md-4 col-12">
                  <div className="creator-single creator-single-short creator-single-filled mb-30">
                    <div className="creator-wraper">
                      <div className="creator-inner">
                        <div className="creator-content pos-rel creator-short-content">
                          <div className="profile-img pos-rel">
                            <a>
                              <img
                                src="assets/img/globl/dashboard/team.svg"
                                alt="profile-img"
                              />
                            </a>
                          </div>
                          <div className="creator-info">
                            <div className>
                              <h4 className="artist-name pos-rel">
                                <a>{dbuser ? dbuser.team : 0}</a>
                              </h4>

                              <div className="artist-meta-item">
                                <div className="artist-created">Team</div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-3 col-lg-3 col-md-4 col-12">
                  <div className="creator-single creator-single-short creator-single-filled mb-30">
                    <div className="creator-wraper">
                      <div className="creator-inner">
                        <div className="creator-content pos-rel creator-short-content">
                          <div className="profile-img pos-rel">
                            <a>
                              <img
                                src="assets/img/globl/dashboard/team.svg"
                                alt="profile-img"
                              />
                            </a>
                          </div>
                          <div className="creator-info">
                            <div className>
                              <h4 className="artist-name pos-rel">
                                <a>{dbuser ? dbuser.direct_team : 0}</a>
                              </h4>

                              <div className="artist-meta-item">
                                <div className="artist-created">Direct Team</div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-xl-3 col-lg-3 col-md-4 col-12">
                  <div className="creator-single creator-single-short creator-single-filled mb-30">
                    <div className="creator-wraper">
                      <div className="creator-inner">
                        <div className="creator-content pos-rel creator-short-content">
                          <div className="profile-img pos-rel">
                            <a>
                              <img
                                src="assets/img/globl/dashboard/dividend.svg"
                                alt="profile-img"
                              />
                            </a>
                          </div>
                          <div className="creator-info">
                            <div className>
                              <h4 className="artist-name pos-rel">
                                <a>${dbuser ? dbuser.staking_rew : 0}</a>
                              </h4>

                              <div className="artist-meta-item">
                                <div className="artist-created">Dividend</div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-3 col-lg-3 col-md-4 col-12">
                  <div className="creator-single creator-single-short creator-single-filled mb-30">
                    <div className="creator-wraper">
                      <div className="creator-inner">
                        <div className="creator-content pos-rel creator-short-content">
                          <div className="profile-img pos-rel">
                            <a>
                              <img
                                src="assets/img/globl/dashboard/direct_reward.svg"
                                alt="profile-img"
                              />
                            </a>
                          </div>
                          <div className="creator-info">
                            <div className>
                              <h4 className="artist-name pos-rel">
                                <a>${dbuser ? dbuser.level_inc : 0}</a>
                              </h4>

                              <div className="artist-meta-item">
                                <div className="artist-created">
                                  Direct Reward
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-3 col-lg-3 col-md-4 col-12">
                  <div className="creator-single creator-single-short creator-single-filled mb-30">
                    <div className="creator-wraper">
                      <div className="creator-inner">
                        <div className="creator-content pos-rel creator-short-content">
                          <div className="profile-img pos-rel">
                            <a>
                              <img
                                src="assets/img/globl/dashboard/level_reward.svg"
                                alt="profile-img"
                              />
                            </a>
                          </div>
                          <div className="creator-info">
                            <div className>
                              <h4 className="artist-name pos-rel">
                                <a>${dbuser ? dbuser.roiofroi_rew : 0}</a>
                              </h4>

                              <div className="artist-meta-item">
                                <div className="artist-created">
                                  Level Reward
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <div className="col-xl-3 col-lg-3 col-md-4 col-12">
                  <div className="creator-single creator-single-short creator-single-filled mb-30">
                    <div className="creator-wraper">
                      <div className="creator-inner">
                        <div className="creator-content pos-rel creator-short-content">
                          <div className="profile-img pos-rel">
                            <a>
                              <img
                                src="assets/img/globl/dashboard/direct_reward.svg"
                                alt="profile-img"
                              />
                            </a>
                          </div>
                          <div className="creator-info">
                            <div className>
                              <h4 className="artist-name pos-rel">
                                <a>$0</a>
                              </h4>

                              <div className="artist-meta-item">
                                <div className="artist-created">Level Open</div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div> */}
                <div className="col-xl-3 col-lg-3 col-md-4 col-12">
                  <div className="creator-single creator-single-short creator-single-filled mb-30">
                    <div className="creator-wraper">
                      <div className="creator-inner">
                        <div className="creator-content pos-rel creator-short-content">
                          <div className="profile-img pos-rel">
                            <a>
                              <img
                                src="assets/img/globl/dashboard/matching_reward.svg"
                                alt="profile-img"
                              />
                            </a>
                          </div>
                          <div className="creator-info">
                            <div className>
                              <h4 className="artist-name pos-rel">
                                <a>${dbuser ? dbuser.rank_rew : 0}</a>
                              </h4>

                              <div className="artist-meta-item">
                                <div className="artist-created">
                                  Matching Reward
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-3 col-lg-3 col-md-4 col-12">
                  <div className="creator-single creator-single-short creator-single-filled mb-30">
                    <div className="creator-wraper">
                      <div className="creator-inner">
                        <div className="creator-content pos-rel creator-short-content">
                          <div className="profile-img pos-rel">
                            <a>
                              <img
                                src="assets/img/globl/dashboard/royal_rank.svg"
                                alt="profile-img"
                              />
                            </a>
                          </div>
                          <div className="creator-info">
                            <div className>
                              <h4 className="artist-name pos-rel">
                                <a>${dbuser ? dbuser.royal_rank_rew : 0}</a>
                              </h4>

                              <div className="artist-meta-item">
                                <div className="artist-created">Royal Rank</div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-xl-3 col-lg-3 col-md-4 col-12">
                  <div className="creator-single creator-single-short creator-single-filled mb-30">
                    <div className="creator-wraper">
                      <div className="creator-inner">
                        <div className="creator-content pos-rel creator-short-content">
                          <div className="profile-img pos-rel">
                            <a>
                              <img
                                src="assets/img/globl/dashboard/direct_reward.svg"
                                alt="profile-img"
                              />
                            </a>
                          </div>
                          <div className="creator-info">
                            <div className>
                              <h4 className="artist-name pos-rel">
                                <a>{dbuser ? dbuser.rank_title : '-'}</a>
                              </h4>

                              <div className="artist-meta-item">
                                <div className="artist-created">
                                  Current Rank
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-3 col-lg-3 col-md-4 col-12">
                  <div className="creator-single creator-single-short creator-single-filled mb-30">
                    <div className="creator-wraper">
                      <div className="creator-inner">
                        <div className="creator-content pos-rel creator-short-content">
                          <div className="profile-img pos-rel">
                            <a>
                              <img
                                src="assets/img/globl/dashboard/next_rank.svg"
                                alt="profile-img"
                              />
                            </a>
                          </div>
                          <div className="creator-info">
                            <div className>
                              <h4 className="artist-name pos-rel">
                                <a>{dbuser ? dbuser.next_rank : '-'}</a>
                              </h4>

                              <div className="artist-meta-item">
                                <div className="artist-created">Next Rank</div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-3 col-lg-3 col-md-4 col-12">
                  <div className="creator-single creator-single-short creator-single-filled mb-30">
                    <div className="creator-wraper">
                      <div className="creator-inner">
                        <div className="creator-content pos-rel creator-short-content">
                          <div className="profile-img pos-rel">
                            <a>
                              <img
                                src="assets/img/globl/dashboard/direct_reward.svg"
                                alt="profile-img"
                              />
                            </a>
                          </div>
                          <div className="creator-info">
                            <div className>
                              <h4 className="artist-name pos-rel">
                                <a>${dbuser ? dbuser.aleg : 0}</a>
                              </h4>

                              <div className="artist-meta-item">
                                <div className="artist-created">Leg A</div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-3 col-lg-3 col-md-4 col-12">
                  <div className="creator-single creator-single-short creator-single-filled mb-30">
                    <div className="creator-wraper">
                      <div className="creator-inner">
                        <div className="creator-content pos-rel creator-short-content">
                          <div className="profile-img pos-rel">
                            <a>
                              <img
                                src="assets/img/globl/dashboard/direct_reward.svg"
                                alt="profile-img"
                              />
                            </a>
                          </div>
                          <div className="creator-info">
                            <div className>
                              <h4 className="artist-name pos-rel">
                                <a>${dbuser ? dbuser.cleg : 0}</a>
                              </h4>

                              <div className="artist-meta-item">
                                <div className="artist-created">Leg C</div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-xl-3 col-lg-3 col-md-4 col-12">
                  <div className="creator-single creator-single-short creator-single-filled mb-30">
                    <div className="creator-wraper">
                      <div className="creator-inner">
                        <div className="creator-content pos-rel creator-short-content">
                          <div className="profile-img pos-rel">
                            <a>
                              <img
                                src="assets/img/globl/dashboard/total_withdrawal.svg"
                                alt="profile-img"
                              />
                            </a>
                          </div>
                          <div className="creator-info">
                            <div className>
                              <h4 className="artist-name pos-rel">
                                <a>${dbuser ? dbuser.t_withdrawal : 0}</a>
                              </h4>

                              <div className="artist-meta-item">
                                <div className="artist-created">
                                  Total Withdrawal
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-3 col-lg-3 col-md-4 col-12">
                  <div className="creator-single creator-single-short creator-single-filled mb-30">
                    <div className="creator-wraper">
                      <div className="creator-inner">
                        <div className="creator-content pos-rel creator-short-content">
                          <div className="profile-img pos-rel">
                            <a>
                              <img
                                src="assets/img/globl/dashboard/pending_withdrawal.svg"
                                alt="profile-img"
                              />
                            </a>
                          </div>
                          <div className="creator-info">
                            <div className>
                              <h4 className="artist-name pos-rel">
                                <a>${dbuser ? dbuser.pen_withdrawal : 0}</a>
                              </h4>

                              <div className="artist-meta-item">
                                <div className="artist-created">
                                  Pending Withdrawal
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
