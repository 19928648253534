import React, { useContext, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { ProjectContext } from "../../ProjectContext";
import axios from "axios";

export default function Header() {
  const { account, disconnectNow, dbuser, copyaddress, formatAddress, connectMetamask } = useContext(ProjectContext);

  const [isMenuOpen, setIsMenuOpen] = useState(true);
  const navigate = useNavigate();
  const logOut = async () => {
    try {
      let userData = localStorage.getItem("user1f2s24dsaf7");
      let user = JSON.parse(userData);
      const response = await axios.post(process.env.REACT_APP_BACKEND_LINK, {
        method: "logout",
        submethod: "insert",
        key: process.env.REACT_APP_KEY,
        username: user.username,
        _id: user.id,
      });
      const { resCode } = response.data;
      if (resCode === 200) {
        localStorage.clear();
        navigate("/");
      }
    } catch (err) {
      console.error("Error inserting data:", err);
    }
  };
  // Toggle function to open/close menu
  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };
  return (
    <div>
      <div>
        <header className="header1 oc-transparent-header">
          <div id="header-sticky" className="header-main header-main1">
            <div className="container header-container">
              <div className="row align-items-center">
                <div className="col-xl-2 col-lg-2 col-md-4 col-4">
                  <div className="header-main-left">
                    <div className="header-logo header1-logo">
                      <Link to="/" className="logo-bb">
                        <img
                          src="assets/img/globl/logo.png"
                          alt="logo-img"
                          width={200}
                        />
                      </Link>
                      <Link to="/" className="logo-bw">
                        <img
                          src="assets/img/globl/logo.png"
                          alt="logo-img"
                          width={200}
                        />
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="col-xl-10 col-lg-10 col-md-8 col-8">
                  <div className="header-main-right">
                    <div className="main-menu main-menu1 d-none d-lg-block">
                      <nav id="mobile-menu">
                        <ul>
                          <li className="menu-item-h">
                            <Link to="#">Features</Link>
                          </li>
                          <li>
                            <Link to="#">Trading Bots</Link>
                          </li>
                          <li>
                            <Link to="#">Copy-Trading</Link>
                          </li>
                          <li className="menu-item-ha">
                            <Link to="#">Pricing</Link>
                          </li>
                          <li>
                            <Link to="#">Blog</Link>
                          </li>
                          {/* <li>
                            <a href="contact.html">Contact</a>
                          </li> */}
                        </ul>
                      </nav>
                    </div>

                    <div className="header-btn ml-20 d-none d-lg-block">
                      {account ?
                        <Link to="/dashboard" className="fill-btn">
                          {formatAddress(account)}
                        </Link> :
                        <>
                          <Link to="/registration" className="fill-btn mx-1">
                            Registration
                          </Link>
                          
                          <Link to="/login" className="fill-btn mx-1">
                            Login
                          </Link>
                        </>
                      }
                    </div>
                    <div className="profile-item profile-item-header ml-20 d-none d-md-inline-block pos-rel"></div>
                    <div className="menu-bar d-block d-lg-none ml-20">
                      <a className="side-toggle" href="javascript:void(0)">
                        <div className="bar-icon" onClick={toggleMenu}>
                          <span />
                          <span />
                          <span />
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </header>
        {/* header area end */}
        {/* side toggle start */}
        <div className="fix">
          <div
            className={isMenuOpen ? "side-info  d-none" : "side-info info-open"}
          >
            <div className="side-info-content">
              <div className="offset-widget offset-logo mb-40">
                <div className="row align-items-center">
                  <div className="col-9">
                    <a href="/">
                      <img
                        src="assets/img/globl/logo.png"
                        width={200}
                        alt="Logo"
                      />
                    </a>
                  </div>
                  <div className="col-3 text-end">
                    <button className="side-info-close" onClick={toggleMenu}>
                      <i className="fal fa-times" />
                    </button>
                  </div>
                </div>
              </div>
              <div className="mobile-menu d-lg-none fix" />
              <div className="offset-profile-action">
                <div className="offset-widget mb-40">
                  <div className="profile-item profile-item-header into-sidebar">
                    <div className="profile-img ">
                      <div className="main-menu main-menu1 ">
                        <nav>
                          <ul style={{ zIndex: "999", position: "relative" }}>
                            <li className="menu-item-ha text-white">
                              <Link to="#" className="py-1">
                                Features
                              </Link>
                            </li>
                            <li className="text-white">
                              <Link to="explore-arts.html" className="py-1">
                                Trading Bots
                              </Link>
                            </li>
                            <li className="text-white">
                              <Link tp="creators.html" className="py-1">
                                Copy-Trading
                              </Link>
                            </li>
                            <li className="menu-item-has-children text-white">
                              <Link to="#" className="py-1">
                                Pricing
                              </Link>
                            </li>
                            <li className="text-white">
                              <Link to="#" className="py-1">
                                Blog
                              </Link>
                            </li>
                          </ul>
                        </nav>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="offset-widget offset_searchbar mb-30"></div>
              <div className="offset-widget mb-40">
                {account ?
                    <Link to="/dashboard" className="fill-btn">
                      {formatAddress(account)}
                    </Link> :
                    <>
                      <Link to="/registration" className="fill-btn mx-1">
                        Registration
                      </Link>
                      
                      <Link to="/login" className="fill-btn mx-1">
                        Login
                      </Link>
                    </>
                  }
              </div>
            </div>
          </div>
        </div>
        <div className="offcanvas-overlay " />
        <div className="offcanvas-overlay-white" />
      </div>
    </div>
  );
}