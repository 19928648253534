import React from 'react'
import { useNavigate } from 'react-router-dom';
import axios from "axios";

export default function Header2() {
  const navigate = useNavigate();
  const logOut = async () => {
    try {
      let userData = localStorage.getItem("user1f2s24dsaf7");
      let user = JSON.parse(userData);
      const response = await axios.post(process.env.REACT_APP_BACKEND_LINK, {
        method: "logout",
        submethod: "insert",
        key: process.env.REACT_APP_KEY,
        username: user.username,
        _id: user.id,
      });
      const { resCode } = response.data;
      if (resCode === 200) {
        localStorage.clear();
        navigate("/");
      }
    } catch (err) {
      console.error("Error inserting data:", err);
    }
  };
  return (
    <div>

    </div>
  )
}
