import React, { useContext, useEffect, useState } from "react";
import Header2 from "../Coman/Header2";
import Sidebar from "../Coman/Sidebar";
import { ProjectContext } from "../../ProjectContext";
import axios from "axios";
import Pagination from "../Coman/Pagination";

export default function Team() {
  const { account, copyaddress, formatAddress } = useContext(ProjectContext)
  const [data, setdata] = useState([]);
  const [isLoading, setisLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [pages, setPages] = useState(1);
  const [level, setlevel] = useState(1);

  const getData = async () => {
    if (!account) {
      return;
    }
    setisLoading(true);

    await axios
      .post(process.env.REACT_APP_BACKEND_LINK, {
        method: "user",
        submethod: "level",
        // address: "0x6652e765ccd969816503c40da6e91816517b8691",
        address: account,
        key: process.env.REACT_APP_KEY,
        page: currentPage,
        level: level,
        pageSize: pageSize
      })
      .then((res) => {
        setisLoading(false);
        // var dedata = decryptData(res.data);
        if (res.data.error) {
          return "";
        }
        setdata(res.data.data);
        setPages(Math.ceil(res.data.dataLength / pageSize));
      });
  };
  useEffect(() => {
    getData();
  }, [account, pages, pageSize, currentPage]);
  useEffect(() => {
    getData()
  }, [level])


  return (
    <div>
      <Sidebar />
      <div style={{ backgroundColor: "#eff1f5" }}>
        <div className="banner-area banner-area3 pos-rel pt-130 vh-100">
          <div className="container c-container-1">

            <div className='d-flex justify-content-between'>
              <h2>Team</h2>
              <div className="single-input-unit">
                <div className="input-group">
                  <input type="number" className="form-control m-0" placeholder="Enter Level to Sarch" style={{ width: "300px", height: "49px" }} value={level} onChange={(e) => setlevel(e.target.value)} />
                  <span className="input-group-text fill-btn " id="basic-addon2">Sarch</span>
                </div>

                {/* <input type="number" name="m-id" id="m-id" placeholder="Enter Level to Search" /> */}
              </div>
            </div>


            <div className="table-responsive">
              <table className="table">
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">Leg</th>
                    <th scope="col">Joining Date</th>
                    <th scope="col">Address</th>
                    <th scope="col">Referral</th>
                    <th scope="col">Direct Team</th>
                    <th scope="col">Direct Business</th>
                    <th scope="col">Rank</th>
                    <th scope="col">Total Staking</th>
                    <th scope="col">Status</th>
                  </tr>
                </thead>
                <tbody>
                  <tr className="text-center">
                    <td className="text-center" colSpan={10}>
                      {isLoading ? "Data is loading" : ""}
                    </td>
                  </tr>
                  {!isLoading ? (
                    !data.length ? (
                      <tr className="text-center">
                        <td className="text-center" colSpan={10}>
                          <span className="w-100">No data found</span>
                        </td>
                      </tr>
                    ) : (
                      ""
                    )
                  ) : (
                    ""
                  )}
                  {data.map((ele, i) => {
                    return (
                      <tr>
                        <td>
                          {ele.id}
                        </td>
                        <td>
                          {ele.leg}
                        </td>
                        <td>
                          {ele.createdAt}
                        </td>
                        <td>
                          <span onClick={() =>
                            copyaddress(ele.address)
                          }>
                            {formatAddress(ele.address)}  <i className="fa fa-copy"></i>
                          </span>
                        </td>
                        <td>
                          <span onClick={() =>
                            copyaddress(ele.ref_address)
                          }>
                            {formatAddress(ele.ref_address)}  <i className="fa fa-copy"></i>
                          </span>
                        </td>
                        <td>
                          {ele.ref_team}
                        </td>
                        <td>
                          $ {ele.direct_team_business}
                        </td>
                        <td>
                          {ele.rank_title}
                        </td>
                        <td>
                          $ {ele.t_staking}
                        </td>
                        <td>
                          {ele.status ? (
                            <div className="text-success">
                              Active
                            </div>
                          ) : (
                            <div className="text-danger">
                              Not Active
                            </div>
                          )}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
              <Pagination
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                pageSize={pageSize}
                setPageSize={setPageSize}
                pages={pages}
              />
            </div>

          </div>
        </div>
      </div>
    </div>
  )
}
