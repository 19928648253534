import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { ProjectContext } from "../../ProjectContext";
import axios from "axios";

export default function Sidebar() {
  const { disconnectNow, dbbalance, logOut } = useContext(ProjectContext);
  const navigate = useNavigate();

  useEffect(() => {
    var checl = localStorage.getItem("odkpfub");
    if (checl !== "5Dg4ZCDAxWoxjgGHRj6BAMQ4dUDC") {
      navigate("/");
    }
  }, [localStorage]);
  const [isSideOpen, setIsSideOpen] = useState(true);
  const currentPath = window.location.pathname;

  // Toggle function to open/close menu
  const toggleSide = () => {
    setIsSideOpen(!isSideOpen);
  };
  const logout = () => {
    logOut();
    navigate("/");
  };

  return (
    <div>
      <header className="header2">
        <div className="header-main header-main2">
          <div style={{ position: "relative", left: "0" }}>
            <div className="header-main2-content">
              <div className="row align-items-center">
                <div className="col-xl-6 col-lg-5 col-md-4 col-1">
                  <div className="header-main-left">
                    <div className="menu-bar mr-20 d-xxl-none">
                      <a className="side-toggle" href="javascript:void(0)">
                        <div
                          className="bar-icon left-bar-icon"
                          onClick={toggleSide}
                        >
                          <span />
                          <span />
                          <span />
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-xl-6 col-lg-7 col-md-8 col-11">
                  <div className="header-main-right">
                    <div className="header-btn ml-20 ">
                      {/* <a
                        href="#"
                        className="fill-btn mx-2 mt-2 mt-sm-0 px-2 px-md-3"
                        style={{ boxShadow: "1px 2px 10px 0px #000000" }}
                      >
                        $ {dbbalance}
                      </a> */}
                      <a
                        href="#"
                        className="fill-btn mx-2 mt-2 mt-sm-0 px-2 px-md-3"
                        style={{ boxShadow: "1px 2px 10px 0px #000000" }}
                      >
                        <span>
                          <i class="fas fa-wallet " />
                        </span>{" "}
                        &nbsp;$ {dbbalance}
                      </a>
                      <a
                        href="#"
                        className="fill-btn mx-2 mt-2 mt-sm-0 px-2 px-md-3"
                        style={{ boxShadow: "1px 2px 10px 0px #000000" }}
                        onClick={() => logout()}
                      >
                        Logout
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>

      <div>
        <div className="fix">
          <div
            className={
              isSideOpen
                ? "menu2-side-bar-wrapper "
                : "menu2-side-bar-wrapper open "
            }
          >
            <div className="menu2-side-bar vh-100">
              <div className="side-info-content">
                <div className="offset-widget offset-logo mb-25">
                  <div className="row align-items-center">
                    <div className="col-12">
                      <div className="header-logo header1-logo d-flex justify-content-between">
                        <Link to="/dashboard" className="logo-bb">
                          <img
                            src="/assets/img/globl/logo.png"
                            width={180}
                            alt="logo-img"
                          />
                        </Link>

                        <button
                          className="side-info-close d-none-side-icon"
                          onClick={toggleSide}
                        >
                          <i
                            className="fal fa-times"
                            style={{ color: "black" }}
                          />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="main-menu main-menu1 ">
                  <nav id="menu2-mobile-menu">
                    <ul>
                      <li className=" side-w m-0">
                        <Link
                          to="/dashboard"
                          className={`${currentPath === "/dashboard"
                            ? " p-0 side-active"
                            : "p-0  "
                            }`}
                        >
                          <img
                            src="/assets/img/globl/sidebar/dashboard.svg"
                            width={30}
                            alt=""
                          />{" "}
                          &nbsp; Dashboard
                        </Link>
                      </li>
                      <li className="side-w m-0">
                        <Link
                          to="/staking"
                          className={`${currentPath === "/staking"
                            ? " p-0 side-active"
                            : " p-0 "
                            }`}
                        >
                          <img
                            src="/assets/img/globl/sidebar/staking.svg"
                            width={30}
                            alt=""
                          />{" "}
                          &nbsp; Staking
                        </Link>
                      </li>
                      <li className="side-w m-0">
                        <Link
                          to="/team"
                          className={`${currentPath === "/team"
                            ? " p-0 side-active"
                            : " p-0 "
                            }`}
                        >
                          {" "}
                          <img
                            src="/assets/img/globl/sidebar/team.svg"
                            width={30}
                            alt=""
                          />{" "}
                          &nbsp; Team
                        </Link>
                      </li>
                      <li className="side-w m-0">
                        <Link
                          to="/dividend"
                          className={`${currentPath === "/dividend"
                            ? " p-0 side-active"
                            : " p-0 "
                            }`}
                        >
                          {" "}
                          <img
                            src="/assets/img/globl/sidebar/dividend.svg"
                            width={30}
                            alt=""
                          />{" "}
                          &nbsp; Dividend
                        </Link>
                      </li>
                      <li className="side-w m-0">
                        <Link
                          to="/direct-reward"
                          className={`${currentPath === "/direct-reward"
                            ? " p-0 side-active"
                            : " p-0 "
                            }`}
                        >
                          {" "}
                          <img
                            src="/assets/img/globl/sidebar/direct_reward.svg"
                            width={30}
                            alt=""
                          />{" "}
                          &nbsp; Direct Reward
                        </Link>
                      </li>
                      <li className="side-w m-0">
                        <Link
                          to="/level-reward"
                          className={`${currentPath === "/level-reward"
                            ? " p-0 side-active"
                            : " p-0 "
                            }`}
                        >
                          {" "}
                          <img
                            src="/assets/img/globl/sidebar/level_reward.svg"
                            width={30}
                            alt=""
                          />{" "}
                          &nbsp; Level Reward
                        </Link>
                      </li>
                      <li className="side-w m-0">
                        <Link
                          to="/matching-reward"
                          className={`${currentPath === "/matching-reward"
                            ? " p-0 side-active"
                            : " p-0 "
                            }`}
                        >
                          {" "}
                          <img
                            src="/assets/img/globl/sidebar/matching_reward.svg"
                            width={30}
                            alt=""
                          />{" "}
                          &nbsp;Matching Reward
                        </Link>
                      </li>
                      <li className="side-w m-0">
                        <Link
                          to="/royal-rank"
                          className={`${currentPath === "/royal-rank"
                            ? " p-0 side-active"
                            : " p-0 "
                            }`}
                        >
                          {" "}
                          <img
                            src="/assets/img/globl/sidebar/royal_rank.svg"
                            width={30}
                            alt=""
                          />{" "}
                          &nbsp;Royal Rank
                        </Link>
                      </li>
                      <li className="side-w m-0">
                        <Link
                          to="/withdrawal"
                          className={`${currentPath === "/withdrawal"
                            ? " p-0 side-active"
                            : " p-0 "
                            }`}
                        >
                          {" "}
                          <img
                            src="/assets/img/globl/sidebar/withdraw.svg"
                            width={30}
                            alt=""
                          />{" "}
                          &nbsp;Withdrawal
                        </Link>
                      </li>
                      <li className="side-w m-0">
                        <Link
                          to="/withdrawal-history"
                          className={`${currentPath === "/withdrawal-history"
                            ? " p-0 side-active"
                            : " p-0 "
                            }`}
                        >
                          {" "}
                          <img
                            src="/assets/img/globl/sidebar/withdraw.svg"
                            width={30}
                            alt=""
                          />{" "}
                          &nbsp;Withdrawal History
                        </Link>
                      </li>
                      <li className="side-w m-0">
                        <a
                          href="./../assets/global_grow_presenataion.pdf" target="_blank"
                          className={`${currentPath === "#" ? " p-0 side-active" : " p-0 "
                            }`}
                          >
                          {" "}
                          <img
                            src="/assets/img/globl/sidebar/presentation.svg"
                            width={30}
                            alt=""
                          />{" "}
                          &nbsp;Presentation
                        </a>
                      </li>
                    </ul>
                  </nav>
                </div>
                <div className="menu2-mobile-menu fix"></div>
                <a href="#" className="fill-btn" onClick={() => logout()}>
                  Logout
                </a>
              </div>
            </div>
          </div>
        </div>
        <div
          className={
            isSideOpen ? "offcanvas-overlay" : "offcanvas-overlay overlay-open"
          }
        />

        <div className="offcanvas-overlay-white" />
      </div>
    </div>
  );
}
